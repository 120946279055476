import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 20px 100px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  color: white;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 34px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  // flex: 1;
  flex-direction: column;
  // justify-content: stretched;
  // align-items: stretched;
  // border: 1px solid salmon;
  width: 100%;
  // @media (min-width: 767px) {
  //   flex-direction: row;
  // }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const LandingHero = styled.img`
  width: 684px;
  padding-top: 40px;
  @media (max-width: 767px) {
    padding-top: 10px;
    width: 100%;
  }
  transition: width 0.5s;
  transition: height 0.5s;
  padding-left: 10px;
  padding-right: 10px;
`;

export const OpenSeaLink = styled.div`
  position: absolute;
  font-size: 20px;
  right: 40px; 
  top: 30px;
  border: 2px solid var(--secondary);
  padding: 10px 20px;
  border-radius: 30px;
  @media (max-width: 767px) {
    position: inherit;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

export const BodySection = styled.div`
  max-width: 828px;
  display: flex;
  flex:1;
  @media (max-width: 767px) {
    
  }
`;

export const BodySectionColumn = styled.div`
  border: 1px solid pink;
  display: flex;
  flex: 1;
`;

export const StyledImg = styled.img`
  width: 36px;
  height: 36px;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
  margin-left: 6px;
  margin-right: 6px;
`;

export const Footer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: black;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  max-width: 828px;
  height: 300px;
  @media (max-width: 767px) {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px; 
  }
`;

export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

export const SocialIconsWrapper =  styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 36px;
`;

export const SocialIcons =  styled.img`
  width: 36px;
  height: 36px;
  display: flex;
  margin-left:20px;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to begin minting your NFT`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ 
          paddingTop: 24, 
          paddingRight: 0, 
          paddingLeft: 0, 
          paddingBottom: 0, 
          backgroundColor: "var(--primary)"
        }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <s.TextTitle
            style={{ textAlign: "center", color: "var(--accent-text)", fontSize: "22px", letterSpacing: "-2px", marginTop: "6px", }}
          >
            From the Designer of the MetaMask logo comes his first NFT collection
        </s.TextTitle>
        <s.SpacerSmall />
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        <s.TextTitle
            style={{ textAlign: "center", color: "var(--accent-text)", fontSize: "22px", letterSpacing: "-2px", marginTop: "6px", }}
          >
            No roadmaps, only road sodas
        </s.TextTitle>
        <OpenSeaLink>
          <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
            View on Opensea
          </StyledLink>
        </OpenSeaLink>
        <LandingHero alt={"LandingHero"} src={"/config/images/bh-hero.png"} />
        <s.SpacerSmall />
        <s.TextTitle
            style={{ textAlign: "center", color: "var(--accent-text)", fontSize: "48px", letterSpacing: "-4px", lineHeight: "40px", marginBottom: "10px", }}
          >
            Hello fren, mint a hipster?
        </s.TextTitle>

        {/* MINT COMPONENT WRAPPER */}
        <ResponsiveWrapper flex={1}> 
          <s.Container
            flex={1}
            // jc={"center"}
            ai={"center"}
          >
            <s.SpacerSmall></s.SpacerSmall>
 
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>

                {/* MINTER */}
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    {/* CONNECT BUTTON */}
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>

                    <s.SpacerSmall />
                    
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>

                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                {/* TOTAL SUPPLY COUNTER */}
                <s.TextTitle
                  style={{
                    textAlign: "center",
                    fontSize: 24,
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  {data.totalSupply} of {CONFIG.MAX_SUPPLY} Minted
                </s.TextTitle>
                <s.SpacerSmall />
              {/* MINT NUMBER CONTROLS */}
                    <s.Container ai={"center"} jc={"center"} fd={"row"} 
                    style={{
                      border: "2px solid black",
                      width: "220px",
                      borderRadius: "100px",
                      paddingTop: "0px" ,
                      paddingRight: "20px" ,
                      paddingBottom: "0px",
                      paddingLeft: "20px" ,
                    
                    }}
                      > 
                      <StyledRoundButton 
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                          fontSize: "50px",
                          minWidth: "50px"
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                    </s.Container>
                    <s.SpacerSmall />
                {/* CLICK BUY to MINT FEEDBACK */}
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback} 
                    </s.TextDescription>

                    <s.SpacerMedium />

                    <s.TextTitle
                      style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                      1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                      {CONFIG.NETWORK.SYMBOL}
                    </s.TextTitle>
                  </>
                )}
              </>
            )}
            <s.SpacerSmall />

            {/* CONTRACT */}
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
                background: "rgb(245 245 245)",
                padding: "10px 20px",
                borderRadius: "10px"
              }}
            >
              <s.TextDescription>View our contract:</s.TextDescription>
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
              {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            
            <s.SpacerSmall />
           
          </s.Container>
          
        </ResponsiveWrapper>
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            Please make sure you are connected to the {CONFIG.NETWORK.NAME} Mainnet. 
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription>
            <StyledLink target={"_blank"} href={"https://support.opensea.io/hc/en-us/articles/1500012889322-How-do-I-purchase-NFTs-on-Polygon-"}>
              Learn how to purchase NFTs on Polygon →
            </StyledLink>
              </s.TextDescription>
          <s.SpacerLarge />

        </s.Container>
        <s.SpacerLarge></s.SpacerLarge>
        <BodySection>
          <s.TextTitle
              style={{ textAlign: "center", color: "var(--accent-text)", fontSize: "36px", letterSpacing: "-4px" }}
            >
              What are Bored Hipsters?
          </s.TextTitle>
        </BodySection>
        <s.SpacerXSmall></s.SpacerXSmall>
        <BodySection style={{ display: "flex", textAlign: "center", flexDirection: "column", textAlign: "center", }}>
          <s.TextDescription> 
            Bored Hipsters is a collection of 500 unique artworks hand-drawn by the creator of the MetaMask logo, 
            <StyledLink target={"_blank"} href={"https://twitter.com/cjeria"}>
              Christian Jeria.
            </StyledLink>
            
            This collection represents the diverse people who live in cities and urban areas around the world where art and self-expression are celebrated. Each NFT uniquely reflects how many of us have likely felt during this pandemic - BORED! We hope this collection brings a smile to your face and occupies your boredom :).
          </s.TextDescription>
          <s.SpacerSmall></s.SpacerSmall>
          <s.TextDescription>Join us on this road-mapless journey. </s.TextDescription>
        </BodySection>
        <s.SpacerLarge></s.SpacerLarge>
        <s.SpacerLarge></s.SpacerLarge>

        <BodySection>

          <LandingHero alt={"LandingHero"} src={"/config/images/bh-group.png"}
            style={{
              width: "100%",
              maxWidth: "419px",
            }}
          />
        </BodySection>

        <Footer>
              <FooterWrapper> 
                <FooterColumn>
                  <StyledLogo alt={"logo"} src={"/config/images/Logo_footer.png"} />
                  <s.SpacerSmall></s.SpacerSmall>
                  <s.TextDescription 
                  style={{
                    color:"white",
                  }}
                  >Copyright 2022, Bored Hipsters, All rights reserved.</s.TextDescription>
                </FooterColumn>
                <FooterColumn>
                  <SocialIconsWrapper>
                    <StyledLink alt={"twitter"}  href={"https://twitter.com/BoredHipsters"} >
                      <StyledImg src={"/config/images/twitter.png"}></StyledImg>
                    </StyledLink>
                    <s.SpacerSmall></s.SpacerSmall>
                    <StyledLink alt={"instagram"}  href={"https://www.instagram.com/boredhipsters/"} >
                      <StyledImg src={"/config/images/instagram.png"}></StyledImg>
                    </StyledLink>
                  </SocialIconsWrapper>
                </FooterColumn>
              </FooterWrapper>
        </Footer>

      </s.Container>
    </s.Screen>
  );
}

export default App;
